@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
    .block-container {
        margin: 0 auto;
        box-sizing: border-box;
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        border-radius: 3px;
        padding: 0;
        background: #f5f7fa;
        border: 1px solid #c4c9d8;
        box-shadow: inset 0 2px #fff, 0 1px 3px rgba(0, 0, 0, 0.05);
    }

    .input-container {
        width: 18rem;
    }

	@media (min-width: 640px) {
        .input-container {
            margin-right: 1.25rem;
        }
    }

    @media (min-width: 1024px) {
        .input-container {
            width: 18rem;
        }
    }

    @media (min-width: 1280px) {
        .input-container {
            margin-right: 2.5rem;
        }
    }

    h1 {
        font-family: aktiv-grotesk, sans-serif;
        font-weight: 300;
        font-style: normal;
    }

    h2,h3,h4,h5,h6 {
        font-family: futura-pt, sans-serif;
        font-style: normal;
        font-weight: 500;
        --tw-text-opacity: 1;
        color: rgba(17, 24, 39, var(--tw-text-opacity));
        line-height: 1.5rem;
    }

    h2 {
        font-size: 1.25rem;
    }

    h3 {
        font-size: 1.125rem;
    }

    p,li,a,span,table,div,select,option,input,button,th,td {
        font-family: futura-pt, sans-serif;
        font-weight: 400;
        font-style: normal;
        font-size: 0.875rem;
        line-height: 1.25rem;
    }

    .button {
        margin-right: 0.25rem;
        font-size: 0.875rem;
        width: auto;
        line-height: 1.25rem;
        display: inline-flex;
        justify-content: center;
        border-width: 1px;
        border-radius: 0.375rem;
        --tw-border-opacity: 1;
        border-color: rgba(209, 213, 219, var(--tw-border-opacity));
        padding: 0.5rem 1rem;
        background: white;
        --tw-text-opacity: 1;
        color: rgba(55, 65, 81, var(--tw-text-opacity));
        --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
        box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    }

    .button:hover {
        --tw-bg-opacity: 1;
        background-color: rgba(249, 250, 251, var(--tw-bg-opacity));
        cursor: pointer;
    }

    .button:focus {
        outline: 2px solid transparent;
        outline-offset: 2px;
        --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
        --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
        box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
        --tw-ring-offset-width: 2px;
        --tw-ring-opacity: 1;
        --tw-ring-color: rgba(59, 130, 246, var(--tw-ring-opacity));
    }

    .blue {
        --tw-bg-opacity: 1;
        background-color: rgba(47, 63, 87, var(--tw-bg-opacity));
        --tw-text-opacity: 1;
        color: rgba(255, 255, 255, var(--tw-text-opacity));
    }
    .blue:hover {
        --tw-bg-opacity: 1;
        background-color: rgba(41, 54, 74, var(--tw-bg-opacity));
    }

    .blue:focus {
        --tw-bg-opacity: 1;
        background-color: rgba(41, 54, 74, var(--tw-bg-opacity));
        --tw-ring-opacity: 1;
        --tw-ring-color: rgba(47, 63, 87, var(--tw-bg-opacity));
    }

    .red {
        --tw-bg-opacity: 1;
        background-color: rgba(220, 38, 38, var(--tw-bg-opacity));
        --tw-text-opacity: 1;
        color: rgba(255, 255, 255, var(--tw-text-opacity));

    }
    .red:hover {
        --tw-bg-opacity: 1;
        background-color: rgba(185, 28, 28, var(--tw-bg-opacity));
    }
    .red:focus {
        --tw-bg-opacity: 1;
        background-color: rgba(185, 28, 28, var(--tw-bg-opacity));
        --tw-ring-opacity: 1;
        --tw-ring-color: rgba(239, 68, 68, var(--tw-ring-opacity));
    }

    .green {
        background: #118200;
        --tw-text-opacity: 1;
        color: rgba(255, 255, 255, var(--tw-text-opacity));
    }



}